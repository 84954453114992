<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import VueToastr from "vue-toastr";
import { BASE_URL } from "../../../baseconstant";

/**
 * Profile component
 */
export default {
	components: { Layout, PageHeader, VueToastr },
	page: {
		title: "Sales Summary",
		meta: [
			{
				name: "description",
				content: appConfig.description,
			},
		],
	},
	data() {
		return {
			title: "Sales Summary",
			items: [
				{
					text: "Dashboard",
				},
				{
					text: "Sales summary",
					active: true,
				},
			],
			date_from: "",
			date_to: "",
			isLoading: false,
			dashboard: [],
		};
	},
	methods: {
		fetchData() {
			this.isLoading = !this.isLoading;
			this.axios
				.get(
					`${BASE_URL}/admin/dashboard/stats${
						this.date_from !== "" ? `?from=${this.date_from}&to=${this.date_to}` : ""
					}`
				)
				.then((res) => {
					this.dashboard = res.data.data;
				})
				.catch((err) => {
					// this.error = true
					this.$refs.mytoast.Add({
						msg: err.response?.data?.error,
						clickClose: false,
						timeout: 5000,
						position: "toast-top-right",
						type: "error",
					});
					console.log(err.response.data);
					if (err.response.status == 401) {
						return this.$router.push({ path: "/login" });
					}
				})
				.finally(() => {
					this.isLoading = false;
				});
		},
		resetData() {
			this.date_from = "";
			this.date_to = "";
			this.fetchData();
		},
	},
	mounted() {
		this.fetchData();
	},
};
</script>

<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<vue-toastr ref="mytoast"></vue-toastr>

		<div class="row align-items-center mb-3">
			<!-- Search -->
			<div class="col-sm-12 d-md-flex align-items-center">
				<div id="tickets-table_filter" class="dataTables_filter text-md-end me-2">
					<label class="d-inline-flex align-items-center fw-normal">
						Filter from:
						<b-form-input
							type="date"
							v-model="date_from"
							style="width: 150px;"
							class=" ms-2 form-control form-control-sm"
						></b-form-input>
					</label>
				</div>
				<div id="tickets-table_filter" class="dataTables_filter text-md-end">
					<label class="d-inline-flex align-items-center fw-normal">
						to:
						<b-form-input
							type="date"
							v-model="date_to"
							class="form-control form-control-sm ms-2"
						></b-form-input>
					</label>
					<button
						@click="fetchData()"
						:disabled="this.date_from === '' && this.date_to === ''"
						type="button"
						class="btn mb-1 ms-3 btn-primary py-1 brand-primary"
					>
						<i class="mdi mdi-magnify"></i>
					</button>
					<button
						:disabled="this.date_from === '' && this.date_to === ''"
						@click="resetData()"
						type="button"
						class="btn mb-1 ms-3 btn-primary py-1 brand-primary"
					>
						<i class="mdi mdi-refresh"></i>
					</button>
				</div>
			</div>
			<!-- End search -->
		</div>

		<!-- Loader -->
		<div class="row" v-if="isLoading">
			<div class="col-xl-12">
				<div class="text-center text-primary my-2 mt-5">
					<b-spinner class="align-middle pl-3"></b-spinner>
					<strong>Loading...</strong>
				</div>
			</div>
		</div>

		<div class="row mb-4" v-if="!isLoading">
			<div class="col-xl-12">
				<div class="card mb-0">
					<b-tabs content-class="p-4" justified class="nav-tabs-custom">
						<b-tab active>
							<template v-slot:title>
								<i class="uil uil-user-circle font-size-20"></i>
								<span class="d-none d-sm-block">Sales Summary</span>
							</template>
							<div class="table-responsive">
								<table class="table table-nowrap table-hover mb-0">
									<tbody>
										<tr>
											<td>
												<p class="text-dark">Total MTN sales</p>
											</td>
											<td>
												<p>{{ parseFloat(dashboard.total_mtn).toLocaleString() }}</p>
											</td>
											<td>
												<p class="text-dark">Total Glo sales</p>
											</td>
											<td>
												<p>{{ parseFloat(dashboard.total_glo).toLocaleString() }}</p>
											</td>
										</tr>
										<tr>
											<td>
												<a href="#" class="text-dark">Total MTN Airtime</a>
											</td>
											<td>
												₦ {{ parseFloat(dashboard.total_mtn_airtime).toLocaleString() }}
											</td>
											<td>
												<a href="#" class="text-dark">Total Glo Airtime</a>
											</td>
											<td>
												₦ {{ parseFloat(dashboard.total_glo_airtime).toLocaleString() }}
											</td>
										</tr>
										<tr>
											<td>
												<a href="#" class="text-dark">Total MTN SME</a>
											</td>
											<td>₦ {{ parseFloat(dashboard.total_mtn_sme).toLocaleString() }}</td>
											<td>
												<a href="#" class="text-dark">Total Glo CG</a>
											</td>
											<td>₦ {{ parseFloat(dashboard.total_glo_cg).toLocaleString() }}</td>
										</tr>
										<tr>
											<td>
												<a href="#" class="text-dark">Total MTN direct</a>
											</td>
											<td>
												₦ {{ parseFloat(dashboard.total_mtn_direct).toLocaleString() }}
											</td>
											<td>
												<a href="#" class="text-dark">Total Glo direct</a>
											</td>
											<td>
												₦ {{ parseFloat(dashboard.total_glo_direct).toLocaleString() }}
											</td>
										</tr>
										<tr>
											<td>
												<a href="#" class="text-dark">Total MTN CG</a>
											</td>
											<td>₦ {{ parseFloat(dashboard.total_mtn_cg).toLocaleString() }}</td>
											<td>
												<a href="#" class="text-dark">Total 9mobile sales </a>
											</td>
											<td>
												{{ parseFloat(dashboard.total_ninemobile).toLocaleString() }}
											</td>
										</tr>
										<tr>
											<td>
												<a href="#" class="text-dark">Total MTN DC</a>
											</td>
											<td>₦ {{ parseFloat(dashboard.total_mtn_dc).toLocaleString() }}</td>
											<td>
												<a href="#" class="text-dark">Total 9mobile SME</a>
											</td>
											<td>
												₦ {{ parseFloat(dashboard.total_etisalat_sme).toLocaleString() }}
											</td>
										</tr>
										<tr>
											<td>
												<a href="#" class="text-dark">Total Airtel sales</a>
											</td>
											<td>{{ parseFloat(dashboard.total_airtel).toLocaleString() }}</td>
											<td>
												<a href="#" class="text-dark">Total 9mobile direct</a>
											</td>
											<td>
												₦ {{ parseFloat(dashboard.total_etisalat_direct).toLocaleString() }}
											</td>
										</tr>
										<tr>
											<td>
												<a href="#" class="text-dark">Total Airtel airtime</a>
											</td>
											<td>
												₦ {{ parseFloat(dashboard.total_airtel_airtime).toLocaleString() }}
											</td>
											<td>
												<a href="#" class="text-dark">Total 9mobile airtime</a>
											</td>
											<td>
												₦
												{{ parseFloat(dashboard.total_etisalat_airtime).toLocaleString() }}
											</td>
										</tr>
										<tr>
											<td>
												<a href="#" class="text-dark">Total Airtel CG</a>
											</td>
											<td>
												₦ {{ parseFloat(dashboard.total_airtel_cg).toLocaleString() }}
											</td>
											<td>
												<a href="#" class="text-dark">Total Epins purchase</a>
											</td>
											<td>
												₦ {{ parseFloat(dashboard.total_epins_purchase).toLocaleString() }}
											</td>
										</tr>
										<tr>
											<td>
												<a href="#" class="text-dark">Total Airtel direct</a>
											</td>
											<td>
												₦ {{ parseFloat(dashboard.total_airtel_direct).toLocaleString() }}
											</td>
											<td>
												<a href="#" class="text-dark">Total Electricity purchase</a>
											</td>
											<td>
												₦
												{{
													parseFloat(dashboard.total_electricity_purchase).toLocaleString()
												}}
											</td>
										</tr>
										<tr>
											<td>
												<a href="#" class="text-dark">Total Moniepoint funds</a>
											</td>
											<td>
												₦ {{ parseFloat(dashboard.total_moniepoint_fund).toLocaleString() }}
											</td>
											<td>
												<a href="#" class="text-dark">Total TV purchase</a>
											</td>
											<td>
												₦
												{{ parseFloat(dashboard.total_tv_purchase).toLocaleString() }}
											</td>
										</tr>
										<tr>
											<td>
												<a href="#" class="text-dark">Total Opay funds</a>
											</td>
											<td>
												₦ {{ parseFloat(dashboard.total_opay_fund).toLocaleString() }}
											</td>
											<td>
												<a href="#" class="text-dark">Current Wallet balance</a>
											</td>
											<td>
												₦
												{{ parseFloat(dashboard.total_topup_amount).toLocaleString() }}
											</td>
										</tr>
										<tr>
											<td>
												<a href="#" class="text-dark">Total Flutterwave funds</a>
											</td>
											<td>
												₦
												{{ parseFloat(dashboard.total_flutterwave_fund).toLocaleString() }}
											</td>
											<td>
												<a href="#" class="text-dark">Total Transactions</a>
											</td>
											<td>
												{{ parseFloat(dashboard.total_transactions).toLocaleString() }}
											</td>
										</tr>
										<tr>
											<td>
												<a href="#" class="text-dark">Total Manual funds</a>
											</td>
											<td>
												₦
												{{ parseFloat(dashboard.total_manual_fund).toLocaleString() }}
											</td>
											<td>
												<a href="#" class="text-dark">Total Withdrawals</a>
											</td>
											<td>
												₦ {{ parseFloat(dashboard.total_withdrawals).toLocaleString() }}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</b-tab>
					</b-tabs>
					<!-- Nav tabs -->
					<!-- Tab content -->
				</div>
			</div>
		</div>
		<!-- end row -->
	</Layout>
</template>
